import SectionTitle from './SectionTitle/SectionTitle';
import SocialButton from './SocialButton';
import fbIcon from '../assets/images/fb-grey.png';
import fbHoverIcon from '../assets/images/fb-black.png';
import igIcon from '../assets/images/ig-grey.png';
import igHoverIcon from '../assets/images/ig-black.png';
import linkedinIcon from '../assets/images/linkedin-grey.png';
import linkedinHoverIcon from '../assets/images/linkedin-black.png';
import twitterIcon from '../assets/images/twitter-grey.png';
import twitterHoverIcon from '../assets/images/twitter-black.png';

const socials = [
  { url: 'https://www.facebook.com/hellosirandy', icon: fbIcon, hoverIcon: fbHoverIcon },
  { url: 'https://twitter.com/andyxchien', icon: twitterIcon, hoverIcon: twitterHoverIcon },
  { url: 'https://www.linkedin.com/in/andy-chien-9973b1117/', icon: linkedinIcon, hoverIcon: linkedinHoverIcon },
  { url: 'https://www.instagram.com/andyxchien/', icon: igIcon, hoverIcon: igHoverIcon },
];

export default function ContactSection() {
  const subtitle = 'If you have anything to tell me, talk about sports 🏈, or just want to say hi 👋, feel free to reach out at anytime 🙂.';
  return (
    <div style={{ paddingBottom: 100 }}>
      <SectionTitle>Let&apos;s connect</SectionTitle>
      <h4 style={{
        textAlign: 'center',
        maxWidth: 600,
        margin: 'auto',
        marginTop: -25,
        marginBottom: 30,
        fontSize: '0.9rem',
        color: '#757575',
      }}
      >
        {subtitle}

      </h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {socials.map((social) => <SocialButton key={social.url} social={social} />)}
      </div>
    </div>
  );
}
