import { v4 as uuidv4 } from 'uuid';
import Experience from './Experience/Experience';
import SectionTitle from './SectionTitle/SectionTitle';

const workExperiences = [
  {
    id: uuidv4(),
    title: 'Yahoo Inc.',
    subtitle: 'Software Engineer - Mobile',
    time: 'Mar 2020 - Present',
    location: 'San Francisco, CA',
    paragraphs: [
      { id: uuidv4(), content: 'I have been a huge sports fan since I was a child and a user of Yahoo Sports since high school. Being able to combine my job and interest at the same time is so important and I am truly grateful for that.' },
      { id: uuidv4(), content: 'This opportunity came to me before my internship at Yahoo ended. Yahoo Sports was hiring and I knew that I could not miss this chance to make my dream come true. Apparently, I secured the opportunity and it\'s been going well to this day. Although I still suffer from imposter syndrome, it also motivates me to convince others that I deserve to be here by grinding, improving myself everyday.' },
      { id: uuidv4(), content: 'During the first year, I specialize in Swift/Objective-C and work on the Yahoo Sports iOS App. I then temporarily transferred to the Android side of the same organization since I believed it was a great opportunity to learn about different techstack and benefit our shorthanded team at the same time, which I still think I made the right decision.' },
    ],
  },
  {
    id: uuidv4(),
    title: 'Yahoo Inc.',
    subtitle: 'Software Engineer Intern - Full Stack',
    time: 'Jun 2019 - Aug 2019',
    location: 'Sunnyvale, CA',
    paragraphs: [
      { id: uuidv4(), content: 'I joined Yahoo as a summer intern after I finished the first year in Columbia. I contributed to a performance budget project that was plugged in various Yahoo’s main products’ CI/CD pipeline to prevent poor performance from happening. I gained the experience of working full-stack in JavaScript and handling CI/CD with state-of-the-art resources in hand.' },
      { id: uuidv4(), content: 'I was in awe at the beginning because this was the first time I had a chance to work at a corporation with such a huge scale. At Bay Area, It was a completely different experience compared to living in New York and although it is not comparable when talking about which one is better, to me at the moment, it felt like getting one step closer to the major league since the Bay Area is where the headquarters of most giant tech companies are located.' },
    ],
  },
  {
    id: uuidv4(),
    title: 'Columbia University',
    subtitle: 'M.S. in Computer Science',
    time: 'Sept 2018 - Dec 2019',
    location: 'New York, NY',
    paragraphs: [
      { id: uuidv4(), content: 'During my time in Columbia University, I specialized in Natural Language Processing and was fortunate to have the opportunities to learn from and work with world-class professors who specialized in NLP and Speech Processing. Although 1.5 years was such a short period, I was grateful I had the chance to enhance my domain knowledge in computer science and get familiar with this whole different country and environment.' },
      { id: uuidv4(), content: 'Before actually attending the school, I wasn\'t sure if studying in the big apple was going to be what I wanted. But not long after moving to the city I found that I made the right decision. To me, New York is a place where I\'ll meet all kinds of people and that makes me not only have more knowledge in various walks of life but treat different kinds of people with better respect.' },
      { id: uuidv4(), content: 'It wasn\'t easy to survive the pressure of academy work and living in New York City at the same time. Time management plays a huge role and that was also an important takeaway from this part of my journey.' },
    ],
  },
  {
    id: uuidv4(),
    title: 'GoFreight',
    subtitle: 'Software Engineer Intern - React Native',
    time: 'May 2018 - Aug 2018',
    location: 'Taipei, TW',
    paragraphs: [
      { id: uuidv4(), content: 'After I fulfilled the four-month mandatory military service, I had the opportunity to join an establishing startup as an React Native intern. The company delivers software services for freight business and has been disrupting the industry. At the time I joined, it was a 20-person company and they have around 100 now and are continuously growing.' },
      { id: uuidv4(), content: 'During my time in GoFreight, I developed an iOS app for one of their clients from scratch and launched it to App Store before I left. The main feature of the app was to track shipment status for containers with authentication and comments services provided. In addition to improving my hard programming skill, one of the main takeaways was to deliver products on time while working individually.' },
      { id: uuidv4(), content: 'Although I wanted to stay longer, it had to come to an end because I was about to start my next chapter in my journey - Columbia University.' },
    ],
  },
  {
    id: uuidv4(),
    title: 'Suiqui Inc.',
    subtitle: 'Software Engineer Intern - Full Stack',
    time: 'Jul 2017 - Sept 2017',
    location: 'Taipei, TW',
    paragraphs: [
      { id: uuidv4(), content: 'I hit the job market for an internship after graduating from NTHU and was hired by a startup, founded by an engineer who had around 8 years of experience in Google NYC. From the interview process to style of management, it was nice to know about the culture before I even came to the United States.' },
      { id: uuidv4(), content: 'We were a three-person engineering team working on a Progressive Web App (PWA) for construction business. During my time here, I had the opportunities to code AngularJS, Django and NoSQL. In addition to learning the techstack, it was a great opportunity to learn about the skills of agile development including code review and pair programming, out of the school.' },
      { id: uuidv4(), content: 'While I really enjoyed working at Suiqui, it had to come to an end since mandatory military service was calling.' },
    ],
  },
  {
    id: uuidv4(),
    title: 'National Tsing Hua University',
    subtitle: 'B.S. in Computer Science',
    time: 'Sept 2013 - Jun 2017',
    location: 'Hsinchu, TW',
    paragraphs: [
      { id: uuidv4(), content: 'I knew nothing about programming and computer science out of high school and NTHU was where I learned the fundamentals. I am grateful for the four years I spent here since it paved the way for me to work in the industry that I really love. To be honest, I had no idea I would become a software engineer, even in freshman year. I struggled at the beginning since the concept of programming was so abstract to me but thankfully, I figured out a way to adapt and has been continuously growing since then.' },
      { id: uuidv4(), content: 'In addition to studying, I spent most of my time in badminton. I was a member of the varsity team and played in the collegiate competition held annually. I cherish the experience a lot since that was where I learned about teamwork, leadership, strategy making which helped make me a better person.' },
    ],
  },
];

export default function JourneySection() {
  return (
    <div>
      <SectionTitle>My journey</SectionTitle>
      {workExperiences.map((experience) => (
        <Experience
          key={experience.id}
          experience={experience}
        />
      ))}
    </div>
  );
}
