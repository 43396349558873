import PropTypes from 'prop-types';
import { useState } from 'react';

export default function SocialButton({ social }) {
  const [hovered, setHovered] = useState(false);
  return (
    <a
      style={{ marginLeft: 10, marginRight: 10 }}
      href={social.url}
      onMouseOver={() => setHovered(true)}
      onFocus={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onBlur={() => setHovered(false)}
      target="_blank"
      rel="noreferrer"
    >
      <img width={40} height={40} src={hovered ? social.hoverIcon : social.icon} alt="" />
    </a>
  );
}

SocialButton.propTypes = {
  social: PropTypes.object.isRequired,
};
