import profilePic from '../../assets/images/profile-pic.jpg';
import './BioSection.css';

const shortBio = 'A software engineer from Taiwan and currently based in San Francisco, with a focus in mobile and front-end development.';

export default function BioSection() {
  return (
    <div className="bio-section">
      <img className="profile-small-screen" src={profilePic} alt="" />
      <div className="text-container">
        <h1 className="title">Hey, I&apos;m Andy</h1>
        <h6 className="subtitle">Software Engineer x Sports Enthusiast</h6>
        <hr />
        <p className="short-bio">{shortBio}</p>
      </div>
      <img className="profile-large-screen" src={profilePic} alt="" />
    </div>
  );
}
