import './App.css';
import JourneySection from './components/JourneySection';
import BioSection from './components/BioSection/BioSection';
import ContactSection from './components/ContactSection';
import { logEvent } from './apis/tracker';

export default function App() {
  logEvent('visit');
  return (
    <div className="app-container">
      <BioSection />
      <JourneySection />
      <ContactSection />
    </div>
  );
}
