import PropTypes from 'prop-types';
import './Experience.css';

export default function Experience({ experience }) {
  return (
    <div className="experience">
      <div className="title-container">
        <h3 className="title">{experience.title}</h3>
        <h4 className="subtitle">{experience.location}</h4>
        <h4 className="subtitle">{experience.time}</h4>
      </div>
      <div className="content-container">
        <h3 className="title">{experience.subtitle}</h3>
        {experience.paragraphs.map((paragraph) => <p key={paragraph.id}>{paragraph.content}</p>)}
      </div>
    </div>
  );
}

Experience.propTypes = {
  experience: PropTypes.object.isRequired,
};
