import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAuJy0c0DObHURGwhUl74zGIYi_-9rBCbU',
  authDomain: 'personal-website-f5dd0.firebaseapp.com',
  projectId: 'personal-website-f5dd0',
  storageBucket: 'personal-website-f5dd0.appspot.com',
  messagingSenderId: '254440332003',
  appId: '1:254440332003:web:bf3b48834e411f494c03d6',
  measurementId: 'G-8C1417KNMP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
